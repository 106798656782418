import React, { useState, useEffect, useCallback } from 'react'
import { fetcher } from './utils.js'
import Chart from './Chart.js'
import { Notification } from 'react-bulma-components';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

const SoldReportControl = ({id, isActive, selectedCarId, selectedSearchOptionsUrlSlug}) => {
  const [apiData, setApiData] = useState(null)

  useEffect(() => {
    if (isActive && selectedSearchOptionsUrlSlug && selectedSearchOptionsUrlSlug !== '') {
      fetcher(`/db/get-car-plot-data/${selectedCarId}/${selectedSearchOptionsUrlSlug}`)
        .then((res) => res.json())
        .then((json) => {
          setApiData(json)
        })
    }
  }, [isActive, selectedCarId, selectedSearchOptionsUrlSlug])

  const setupAxesAndSeries = useCallback((chart) => {
    chart.colors.step = 3;
    var yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.renderer.minGridDistance = 50;

    // Create series #1
    var series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "price";
    series.dataFields.dateX = "date";
    series.dataFields.value = "miles";
    series.dataFields.valueDOM = "dom";
    series.strokeOpacity = 0;
    series.name = "Series 1";

    var bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.strokeOpacity = 0.2;
    bullet.stroke = am4core.color("#ffffff");
    bullet.nonScalingStroke = true;
    bullet.tooltipText = "week:{valueX} price:{valueY} miles:{miles} dom:{dom} week: {week} vin: {vin} car: {car}";
    series.heatRules.push({
      target: bullet.circle,
      min: 8,
      max: 26,
      property: "radius"
    });
    bullet.adapter.add("fill", function(fill, target) {
      if (target.dataItem) {
        if (target.dataItem.valueDOM >= 200) {
          return am4core.color("#8B0000")
        }
        else if (target.dataItem.valueDOM >= 150) {
          return am4core.color("#B22222")
        }
        else if (target.dataItem.valueDOM >= 90) {
          return am4core.color("#CD5C5C")
        }
        else if (target.dataItem.valueDOM >= 60) {
          return am4core.color("#FFA07A")
        }
        else if (target.dataItem.valueDOM >= 30) {
          return am4core.color("#FFDAB9")
        }
        else {
          return am4core.color("#aaf0d1");
        }    
      }
      return fill;
    });
}, [apiData])

  return (
    <>
    <Chart id={id} isActive={isActive && (apiData !== null)} setupAxesAndSeries={(chart) => setupAxesAndSeries(chart)} chartData={apiData} />
    <Notification className={isActive && (apiData !== null) ? 'is-size-7' : 'is-size-7 is-hidden'}>
      <p>The chart shows the cars that have sold in the last 2 years.</p>
      <p>The size of the circle represents the mileage. Typically higher mileage cars are priced lower.</p>
      <p>The darker the circle, the longer it has stayed on the market.</p>
      <p style={{marginTop: 8}} className="is-flex is-align-items-center"><img alt="Tip" src="/tip-58.png" style={{width: 32}} />Tip: lots of green circles show that cars are selling fast. Lots of large green circles, cars are selling fast regardless of mileage.</p>
      <p style={{marginTop: 4}} className="is-flex is-align-items-center"><img alt="Tip" src="/tip-58.png" style={{width: 32}} />Tip: seeing a trend where it is changing from light to dark might be used as a leading indicator of falling prices.</p>
    </Notification>
    </>
  )
}

export default SoldReportControl

