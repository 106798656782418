import { useCallback, useEffect, useState } from "react";
import { fetcher } from "./utils";
import { Button, Form, Table, Dropdown } from 'react-bulma-components'

export default function CarSourcesPage() {
  const [apiData, setApiData] = useState(null)
  const [refreshCarSources, setRefreshCarSources] = useState(true)
  const [carsList, setCarsList] = useState(null)
  const [selectedCarId, setSelectedCarId] = useState(null)
  const [urlInput, setUrlInput] = useState('')
  const [sourceInput, setSourceInput] = useState('')
  const [makeIdInput, setMakeIdInput] = useState('')
  const [modelIdInput, setModelIdInput] = useState('')
  const [seriesInput, setSeriesInput] = useState('')
  const [metadataInput, setMetadataInput] = useState('')
  const [apiVerInput, setApiVerInput] = useState('v1')

  useEffect(() => {
    if (refreshCarSources === true) {
      fetcher('/api/cars_sources')
        .then((res) => res.json())
        .then((json) => {
          setApiData(json)
          setRefreshCarSources(false)
        })
    }
  }, [refreshCarSources])

  useEffect(() => {
    fetcher('/db/get-cars-list')
      .then((res) => res.json())
      .then((json) => {
        setCarsList(json.reverse())
      })
  }, [])

  const handleUrlChange = useCallback((value) => {
    if (!value.includes('http')) {
      return
    }
    setUrlInput(value)
    const url = new URL(value);
    const urlSP = new URLSearchParams(url.search);
    if (value.includes('autotrader')) {
      setSourceInput('autotrader')
      setMakeIdInput((urlSP.get('makeCodeList') || '')  + (urlSP.get('makeCode') || ''))
      setModelIdInput((urlSP.get('modelCodeList') || '') + (urlSP.get('modelCode') || ''))
      setSeriesInput((urlSP.get('seriesCodeList') || '') + (urlSP.get('seriesCode') || ''))
      if (urlSP.has('trimCodeList')) {
        setMetadataInput(JSON.stringify(["trimCodeList=" + urlSP.get('trimCodeList')]))      
      }
      if (urlSP.has('vehicleStyleCode')) {
        setMetadataInput(`vehicleStyleCode=${urlSP.get('vehicleStyleCode')}`)
        setApiVerInput('v2')
      }
      if (value.includes('autotrader.com/rest/lsc')) {
        setApiVerInput('v2')
      }
    } else if (value.includes('cargurus')) {
      setSourceInput('cargurus')
      setMakeIdInput('')
      setModelIdInput(urlSP.get('entitySelectingHelper.selectedEntity'))
      if (urlSP.has('trimCodeList')) {
        setMetadataInput(JSON.stringify(["trimCodeList=" + urlSP.get('trimCodeList')]))      
      }
    }
  }, [])

  const handleAddSource = useCallback(() => {
    console.log({selectedCarId, sourceInput, urlInput, makeIdInput, modelIdInput, seriesInput, metadataInput, apiVerInput})
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ obj: {
        car_id: selectedCarId,
        source_name: sourceInput,
        url: urlInput,
        make_id: makeIdInput,
        model_id: modelIdInput,
        series_id: seriesInput,
        extra_metadata: metadataInput,
        api_ver: apiVerInput,
      }})
    }
    fetcher(`/api/cars_sources/add`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        if (json === 'Added') {
          setRefreshCarSources(true)
          setSourceInput('')
          setUrlInput('')
          setMakeIdInput('')
          setModelIdInput('')
          setSeriesInput('')
          setMetadataInput('')
          setApiVerInput('v1')
        } else {
          console.log('Something went wrong')
        }
      })
  }, [selectedCarId, sourceInput, urlInput, makeIdInput, modelIdInput, seriesInput, metadataInput, apiVerInput])

  return (
    <Table style={{fontSize: "85%"}}>
      <thead>
        <tr>
          <th>id</th>
          <th>carId</th>
          <th>Source</th>
          <th>URL</th>
          <th>Make ID</th>
          <th>Model ID</th>
          <th>Series ID</th>
          <th>API Version</th>
          <th>Metadata</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td></td>
          <td>
            <Dropdown label="Cars" value={selectedCarId} hoverable={true} onChange={(value) => setSelectedCarId(value)} closeOnSelect={true}>
              {carsList && carsList.map((item) => {
                return (<Dropdown.Item renderAs="a" value={item.id}>{item.id} - {item.make} {item.model}</Dropdown.Item>)
              })}
            </Dropdown>
          </td>
          <td><Form.Input style={{width: "5vw"}} value={sourceInput} onChange={(e) => setSourceInput(e.target.value)} /></td>
          <td><Form.Input style={{width: "30vw"}} value={urlInput} onChange={(e) => handleUrlChange(e.target.value)} /></td>
          <td><Form.Input style={{width: "5vw"}} value={makeIdInput} /></td>
          <td><Form.Input style={{width: "8vw"}} value={modelIdInput} /></td>
          <td><Form.Input style={{width: "6vw"}} value={seriesInput} /></td>
          <td><Form.Input style={{width: "3vw"}} value={apiVerInput} /></td>
          <td><Form.Input style={{width: "15vw"}} value={metadataInput} /></td>
          <td><Button color="success" size="small" onClick={handleAddSource}>Add</Button></td>
        </tr>
        {apiData && apiData.map((item) => {
          return (
          <tr key={item.id}>
            <td>{item.id}</td>
            <td>{item.car_id}</td>
            <td>{item.source_name}</td>
            <td><Form.Input style={{width: "30vw"}} value={item.url} /></td>
            <td><Form.Input style={{width: "5vw"}} value={item.make_id} /></td>
            <td><Form.Input style={{width: "8vw"}} value={item.model_id} /></td>
            <td><Form.Input style={{width: "6vw"}} value={item.series_id} /></td>
            <td><Form.Input style={{width: "3vw"}} value={item.api_ver} /></td>
            <td><Form.Input style={{width: "15vw"}} value={item.extra_metadata} /></td>
            <td><Button color="warning" size="small">Update</Button></td>
          </tr>
          )
        })}
      </tbody>
    </Table>
  )
}
