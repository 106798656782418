import React, { useState, useEffect, useCallback } from 'react'
import 'bulma/css/bulma.min.css';
import { useLoaderData } from "react-router-dom";
import {priceFormat, fetcher} from './utils.js'
import { Container, Table, Form } from 'react-bulma-components';
import { useGoogleAnalytics, recordGACustom } from './hooks/useGoogleAnalytics.js';
import CarCard from './CarCard.js'
import CostToOwnCalculator from './CostToOwnCalculator.js';
const { Input } = Form;

export async function loader({params}) {
  const res = await fetcher(`/db/get-car-data-by-vin/${params.vin}`)
  return await res.json()
}

export default function VinHistoryPage() {
  useGoogleAnalytics('VIN history')
  const vinHistory = useLoaderData()
  const latestListing = vinHistory.listings[0]
  console.log({latestListing})

  const [shouldShowTCOCalculator, setShouldShowTCOCalculator] = useState(false)
  const [purchasePrice, setPurchasePrice] = useState(0)
  const [medianPrice, setMedianPrice] = useState(0)
  const [yearlyDepreciation, setYearlyDepreciation] = useState(0)

  const handleTracking = (startTracking, vin, listingId) => {
    if (startTracking) {
      fetcher(`/user/track-car/${vin}/${listingId}`)
    } else {
      fetcher(`/user/track-car/archive/${vin}`)
    }
  }
  
  const showTCOCalculator = (carPrice, medianPrice, yearlyDepreciation, link, make, model) => {
    console.log({carPrice, medianPrice, yearlyDepreciation})
    recordGACustom('show_tco_calculator', {
      link: link,
      make: make,
      model: model,
      price: carPrice,
    })
    setPurchasePrice(carPrice)
    setMedianPrice(medianPrice)
    setYearlyDepreciation(yearlyDepreciation)
    setShouldShowTCOCalculator(true)
  }
  
  return (
    <Container>
      <h2>VIN: {vinHistory.vin}</h2>
      <CarCard
        key={latestListing.vin}
        carData={latestListing}
        isSold={latestListing.sold}
        showDealScore={false}
        showPriceDrop={true}
        onClickTrackCar={(startTracking) => handleTracking(startTracking, latestListing.vin, latestListing.id)}
        onClickTCOCalculator={() => showTCOCalculator(latestListing.price, latestListing.listing.medianPrice, latestListing.listing.yearlyDepreciation, latestListing.link, latestListing.make, latestListing.model)}
      />
      <Table hoverable="true" size="fullwidth">
        <thead>
          <tr>
            <th>Week</th>
            <th>DOM</th>
            <th>Seller</th>
            <th>Price</th>
            <th>Miles</th>
            <th>Cargurus Deal Info</th>
          </tr>
        </thead>
        <tbody>
          {vinHistory.listings.map((item) => {
            let hasCargurusDealInfo = item.cargurus_deal_info !== '0'
            let cargurusDealInfo = null
            try {
              cargurusDealInfo = hasCargurusDealInfo && item.cargurus_deal_info.length < 1000 && JSON.parse(item.cargurus_deal_info)
              if (cargurusDealInfo === null || cargurusDealInfo.deal_rating === 'NA') {
                hasCargurusDealInfo = false
              }
            } catch (e) {
              hasCargurusDealInfo = false
            }
            return (
              <tr>
                <td>{item.added_week_num}-{item.added_year}</td>
                <td>{item.days_on_market}</td>
                <td><a href={item.link} target="_blank">{item.seller_name} ({item.seller_state})</a></td>
                <td>{priceFormat.format(item.price)} <span className={item.price_change < 0 ? 'has-text-success' : 'has-text-danger'}>{item.price_change ? '(' + priceFormat.format(item.price_change) + ')' : ''}</span></td>
                <td>{item.miles}</td>
                <td className={hasCargurusDealInfo ? '' : 'is-invisible'}>
                  Deal Rating: {cargurusDealInfo && cargurusDealInfo.deal_rating}<br />
                  Price Under Market: {cargurusDealInfo && cargurusDealInfo.price_under_market}<br />
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      <CostToOwnCalculator
          purchasePrice={purchasePrice}
          medianPrice={medianPrice}
          yearlyDepreciation={yearlyDepreciation}
          show={shouldShowTCOCalculator}
          onClose={() => setShouldShowTCOCalculator(false)}></CostToOwnCalculator>
    </Container>
  )
}