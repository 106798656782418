import React, { useState, useEffect, useCallback } from 'react'
import { fetcher, log } from './utils.js'
import CheckboxListControl from './CheckboxListControl.js'
import RadioListControl from './RadioListControl.js'
import update from 'immutability-helper'
import { recordGACustom } from './hooks/useGoogleAnalytics.js';

export default function SearchOptions({carId, onChange}) {
  const [apiData, setApiData] = useState({
    model_years: [],
    normalized_trims: [],
    trims: [],
    body_styles: [],
  })

  const [selectedData, setSelectedData] = useState({
    model_years: null,
    normalized_trims: null,
    trims: new Set(),
    body_styles: 'All',
    states: '',
  })

  const handleChange = useCallback((value, dataTitle, isChecked) => {
    log(value, dataTitle, isChecked)
    let updatedData
    if (dataTitle === 'model_years') {
      updatedData = update(selectedData, {
        model_years: {$set: parseInt(value)}
      })
      recordGACustom('search_options_changed', { changeType: 'model_years' })
    } else if (dataTitle === 'normalized_trims') {
      updatedData = update(selectedData, {
        normalized_trims: {$set: value}
      })
      recordGACustom('search_options_changed', { changeType: 'normalized_trims' })
    } else if (dataTitle === 'body_styles') {
      updatedData = update(selectedData, {
        body_styles: {$set: value}
      })
      recordGACustom('search_options_changed', { changeType: 'body_styles' })
    } else if (dataTitle === 'ca_only' || dataTitle === 'west_coast_only') {
      if (isChecked) {
        updatedData = update(selectedData, {
          states: {$set: value}
        })
        recordGACustom('search_options_changed', { changeType: 'misc' })
      } else {
        updatedData = update(selectedData, {
          states: {$set: ''}
        })
      }
    } else if (dataTitle === 'trims') {
      if (isChecked) {
        if (value.startsWith('normalized_')) {
          updatedData = update(selectedData, {
            normalized_trims: {$add: [value.replace('normalized_', '')]}
          })
        } else {
          updatedData = update(selectedData, {
            trims: {$add: [value]}
          })
        }
      } else {
        if (value.startsWith('normalized_')) {
          updatedData = update(selectedData, {
            normalized_trims: {$remove: [value.replace('normalized_', '')]}
          })
        } else {
          updatedData = update(selectedData, {
            trims: {$remove: [value]}
          })
        }
      }
      recordGACustom('search_options_changed', { changeType: 'trims' })
    }
    setSelectedData(updatedData)
  }, [selectedData])

  useEffect(() => {
    if (selectedData.model_years != null) {
      onChange(generateUrlSlug(selectedData))
    }
  }, [selectedData])

  useEffect(() => {
    fetcher(`/db/get-car-spec-options/${carId}`)
      .then((res) => res.json())
      .then((json) => {
        setApiData(json)
        let defaultModelYear = null
        if (json.model_years.length >= 4) {
          defaultModelYear = json.model_years[json.model_years.length - 4].model_year
        } else {
          defaultModelYear = json.model_years[0].model_year
        }
        setSelectedData({
          model_years: defaultModelYear,
          normalized_trims: new Set(),
          trims: new Set(),
          body_styles: 'All',
        })
      })
  }, [carId])

  return (
    <>
    <RadioListControl title="Years" dataTitle="model_years" data={apiData.model_years.map(x => x.model_year)} onChange={handleChange} selectedValue={selectedData.model_years} />
    <RadioListControl title="Body Styles (optional)" dataTitle="body_styles" data={apiData.body_styles.map(x => x.body_style)} onChange={handleChange} selectedValue={selectedData.body_styles} />
    {/* <RadioListControl title="Trims (Normalized)" dataTitle="normalized_trims" data={apiData.normalized_trims.map(x => x.normalized_trim)} onChange={handleChange} /> */}
    {/* <p className="menu-label">Misc</p>
    <Form.Checkbox
      name="ca_only"
      className="ca_only"
      value="CA"
      onChange={(e) => { handleChange(e.target.value, "ca_only", e.target.checked)}}
    >CA only</Form.Checkbox><br/>
    <Form.Checkbox
      name="west_coast_only"
      className="west_coast_only"
      value="CA,OR,NV,WA"
      onChange={(e) => { handleChange(e.target.value, "west_coast_only", e.target.checked)}}
    >West Coast</Form.Checkbox> */}
    {/* <CheckboxListControl title="Trims (optional)" dataTitle="trims" data={apiData.trims.map((x) => { return {id: x.is_normalized ? `normalized_${x.trim}` : x.trim, label: `${x.trim} (${x.count})`}})} onChange={handleChange} /> */}
    <CheckboxListControl title="Trims (optional)" dataTitle="trims" data={apiData.trims.map((x) => { return {id: x.is_normalized ? `normalized_${x.trim}` : x.trim, label: x.trim}})} onChange={handleChange} />
    </>
  )
}

function generateUrlSlug(selectedData) {
  const isSpider = (selectedData.body_styles === 'All') ? null : (selectedData.body_styles === 'Convertible') ? 1 : 0
  return `${selectedData.model_years}?trim=${Array.from(selectedData.trims).join('|')}&isspider=${selectedData.body_styles === null ? null : isSpider}&states=${selectedData.states}&normalizedtrim=${Array.from(selectedData.normalized_trims).join('|')}`
}
