import 'bulma/css/bulma.min.css';
import { Form } from 'react-bulma-components';
const { Checkbox } = Form;

const CheckboxListControl = ({title, data, onChange, dataTitle}) => {
  const idTextPrefix = title.toLowerCase().replace(/ /g, '-')
  const name = `${idTextPrefix}-options`

  return (
    <>
    <p className="menu-label">{title}</p>
    <ul>
      {data.map((item) => {
        const idText = `${idTextPrefix}-${item.id}`
        return (
          <>
          <li key={idText}>
            <Checkbox
              key={idText}
              name={name}
              className={name}
              value={item.id}
              id={idText}
              onChange={(e) => {onChange(e.target.value, dataTitle, e.target.checked)}}
            >{item.label}</Checkbox>
          </li>
          </>
        )
      })}
    </ul>
    </>
  )
 }

export default CheckboxListControl