import MainNavbar from "./MainNavbar";
import { useGoogleAnalytics, recordGAPageview } from './hooks/useGoogleAnalytics.js'
import { Columns, Container, Level, Button, Notification, Form } from 'react-bulma-components';
import { useCallback, useState } from "react";
import {roundTwoSigs, fetcher} from './utils.js'
import Chart from "./Chart.js";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

export default function ExploreByPrice() {
  useGoogleAnalytics(`Explore by price`)
  const [apiData, setApiData] = useState(null)

  const handleClick = useCallback((minPrice, maxPrice, minYear, maxYear, maxDepreciation, isSpider) => {
    fetcher(`/db/get-by-median-price-point?minprice=${minPrice}&maxprice=${maxPrice}&minyear=${minYear}&maxyear=${maxYear}&isspider=${isSpider}&maxdep=${maxDepreciation}`)
      .then((res) => res.json())
      .then((json) => {
        setApiData(json)
      })
  }, [])

  return (
    <>
    <MainNavbar />
    <Container className="main">
      <Columns centered="false">
        <Columns.Column size={2}>
          <SimpleSearchOptions onExploreSubmit={handleClick} />
        </Columns.Column>
        <Columns.Column className="main-body" style={{backgroundColor: '#fff', borderLeft: '1px solid #dfdfdf'}}>
          <LineSeriesChart seriesTitles={apiData && apiData.cars} data={apiData && apiData.weeklyData} />
        </Columns.Column>
      </Columns>
    </Container>
    </>
  )
}

export function SimpleSearchOptions({onExploreSubmit}) {
  const [minPrice, setMinPrice] = useState(null)
  const [maxPrice, setMaxPrice] = useState(null)
  const [minYear, setMinYear] = useState(null)
  const [maxYear, setMaxYear] = useState(null)
  const [maxDepreciation, setMaxDepreciation] = useState(null)
  const [isSpider, setIsSpider] = useState(null)

  return (
    <div style={{fontSize: "80%"}}>
      <Form.Label>Price</Form.Label>
      <Level>
        <Form.Input size="small" onChange={(e) => setMinPrice(e.target.value)} placeholder="Min"></Form.Input>
        <div> - </div>
        <Form.Input size="small" onChange={(e) => setMaxPrice(e.target.value)} placeholder="Max"></Form.Input>
      </Level>
      <Form.Label>Model Year</Form.Label>
      <Level>
        <Form.Input size="small" onChange={(e) => setMinYear(e.target.value)} placeholder="Min"></Form.Input>
        <div> - </div>
        <Form.Input size="small" onChange={(e) => setMaxYear(e.target.value)} placeholder="Max"></Form.Input>
      </Level>
      <Form.Label>Max. Yearly Depreciation %</Form.Label>
      <Level>
        <Form.Input size="small" onChange={(e) => setMaxDepreciation(e.target.value)} placeholder="e.g. no more than 10%"></Form.Input>
      </Level>
      <p style={{marginBottom: 16}}>
        <Form.Checkbox size="small" onChange={(e) => setIsSpider(e.target.checked ? 1 : 0)}>Convertible</Form.Checkbox>
      </p>
      <Button color="primary" onClick={() => onExploreSubmit(minPrice, maxPrice, minYear, maxYear, maxDepreciation, isSpider)}>Explore</Button>
    </div>
  )
}

export function LineSeriesChart({seriesTitles, data}) {
  const setupAxesAndSeries = useCallback((chart, seriesTitles) => {
    let valueAxis_prices = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis_prices.renderer.minGridDistance = 50;
    
    for (let t of seriesTitles) {
      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = t;
      series.name = t;
      series.yAxis = valueAxis_prices
      series.dataFields.dateX = "date";
      series.tooltipText = "[#000]{valueY.value}[/]";
      series.tooltip.background.fill = am4core.color("#FFF");
      series.tooltip.label.fill = am4core.color("#00");
      series.tooltip.getFillFromObject = false;
      series.strokeWidth = 2;
      series.tensionX = 0.77;
      series.adapter.add("tooltipText", function(ev) {
        let text = "[bold]{dateX}[/]\n"
        chart.series.each(function(item) {
          if (!item.isHidden && item.dataFields.valueY != null) {
            if (item.name.includes('doms')) {
            } else {
              text += "[" + item.stroke.hex + "]●[/] " + item.name + ": {" + item.dataFields.valueY + "} ({" + item.dataFields.yoyDepValue + "}% YoY) ({" + item.name.substring(0,4) + "-doms} DOM)\n";
            }
          }
        });
        return text;
      });
      // Prevent cross-fading of tooltips
      series.tooltip.defaultState.transitionDuration = 0;
      series.tooltip.hiddenState.transitionDuration = 0;
    }

    chart.legend.itemContainers.template.events.on("over", function(e) {
      let itemContainer = e.target;
    
      // As series list is data of a legend, dataContext is series
      let series = itemContainer.dataItem.dataContext;
      
      chart.series.each(function(chartSeries) {
        if (chartSeries !== series) {
          chartSeries.segments.template.strokeOpacity = 0.15
          chartSeries.segments.template.fillOpacity = 0
        } else {
          chartSeries.segments.template.strokeWidth = 3
        }
      })
    });
    
    // When legend item container is unhovered, make all series as they are
    chart.legend.itemContainers.template.events.on("out", function(e) {
      chart.series.each(function(chartSeries) {
        chartSeries.segments.template.strokeOpacity = 1
        chartSeries.segments.template.strokeWidth = 2
      });
    });
  }, [])

  return (
    <Chart isActive={seriesTitles !== null && data !== null} setupAxesAndSeries={(chart) => setupAxesAndSeries(chart, seriesTitles)} chartData={data} />
  )
}