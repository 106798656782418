import { useCallback, useState } from "react";
import SnapPoll from "./SnapPoll";

export default function NewCarFeedback() {
  const [showForm, setShowForm] = useState(false)

  const poll = {
    id: 'new-car-feedback',
    question: 'What car would you like us to track?',
    typeOfResponse: 'text'
  }

  const handleShowForm = useCallback((e) => {
    e.preventDefault()
    setShowForm(true)
  }, [showForm])

  return (
    <>
    <p>Have a suggestion for a new car to track? <a href="" onClick={(e) => { handleShowForm(e) }}>Let us know.</a></p>
    <SnapPoll id={poll.id} question={poll.question} typeOfResponse={poll.typeOfResponse} options={poll.options} hidden={!showForm} />
    </>
  )
}