import React, { useState, useEffect, useCallback } from 'react'
import { fetcher } from './utils.js'
import Chart from './Chart.js'
import * as am4charts from "@amcharts/amcharts4/charts";
import { ThreadedComments } from './ThreadedComments.js';

export default function ByStateControl({id, isActive, selectedCarId, selectedSearchOptionsUrlSlug}) {
  const [apiData, setApiData] = useState(null)

  useEffect(() => {
    if (isActive && selectedSearchOptionsUrlSlug && selectedSearchOptionsUrlSlug !== '') {
      fetcher(`/db/get-by-state/${selectedCarId}/${selectedSearchOptionsUrlSlug}`)
        .then((res) => res.json())
        .then((json) => {
          setApiData(json)
        })
    }
  }, [isActive, selectedCarId, selectedSearchOptionsUrlSlug])

  const setupAxesAndSeries = useCallback((chart) => {
    console.log(chart.data)
    // Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "state";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    // Create series
    var series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "median";
    series.dataFields.categoryX = "state";
    series.name = "Median Price";
    series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/] ({count} on sale)";
    series.columns.template.fillOpacity = .8;

    var columnTemplate = series.columns.template;
    columnTemplate.strokeWidth = 2;
    columnTemplate.strokeOpacity = 1;
  }, [apiData])

  return (
    <>
    <Chart id={id} isActive={isActive && (apiData !== null)} setupAxesAndSeries={(chart) => setupAxesAndSeries(chart)} chartData={apiData} noDateAxis={true} />
    {/* <ThreadedComments carId={selectedCarId} /> */}
    </>
  )
}
