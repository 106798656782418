import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider, useLocation } from 'react-router-dom';

import { CarHistoricalExplorerPage, CarHistoricalLoader } from './CarHistoricalExplorerPage.js';
import CarSourceManagementPage from './CarSourceManagementPage.js';
import VinHistoryPage, { loader as vinLoader } from './VinHistoryPage.js';
import { MakeSelectorPage, ModelSelectorPage, ModelLoader } from './CarSelectorPage.js';
import { AuthUser, AuthLoader, NoAccessPage, isAuthorized } from './AuthUser.js';
import ForYouPage from './ForYouPage.js';
import TrackedCarsPage from './TrackedCarsPage.js';
import ErrorPage from './ErrorPage.js';
import App from './App.js';
import MacroTrendsPage from './MacroTrendsPage.js'
import UserSearchesPage from './UserSearchesPage.js';
import CarSourcesPage from './CarSourcesPage.js';

import reportWebVitals from './reportWebVitals';
import ExploreByPrice from './ExploreByPrice';

const router = createBrowserRouter([
  {
    path: "noaccess",
    element: <NoAccessPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: "auth/:token",
    element: <AuthUser />,
    loader: AuthLoader,
    errorElement: <ErrorPage />,
  },
  {
    path: "trends",
    element: <MakeSelectorPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "trends/:make",
    element: <ModelSelectorPage />,
    loader: ModelLoader,
    errorElement: <ErrorPage />,
  },
  {
    path: "trends/:carid/:make/:model",
    element: <CarHistoricalExplorerPage />,
    loader: CarHistoricalLoader,
    errorElement: <ErrorPage />,
  },
  {
    path: "explore-by-price",
    element: <ExploreByPrice />,
    errorElement: <ErrorPage />,
  },
  {
    path: "macrotrends",
    element: <MacroTrendsPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "foryou",
    element: <ForYouPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "trackedcars",
    element: <TrackedCarsPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "mg/searches",
    element: <UserSearchesPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "mg/carsources",
    element: <CarSourcesPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "vin/:vin",
    element: <VinHistoryPage />,
    loader: vinLoader,
    errorElement: <ErrorPage />,
  },
]);

if (!isAuthorized() && !window.location.href.includes('noaccess') && !window.location.href.includes('auth')) {
  window.location = '/noaccess'
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
