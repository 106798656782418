import { Box, Heading } from 'react-bulma-components'
import MainNavbar from './MainNavbar'
import { useGoogleAnalytics } from './hooks/useGoogleAnalytics.js'

export default function ForYouPage() {
  useGoogleAnalytics('For You')
  return (
    <>
    <MainNavbar />
    <Box>
      <Heading>Coming Soon</Heading>
    </Box>
    </>
  )
}