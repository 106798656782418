import { fetcher } from './utils.js'
import { NavLink, useLoaderData } from "react-router-dom";
import { Box, Heading, Button } from 'react-bulma-components'
import MainNavbar from './MainNavbar.js';
import { useGoogleAnalytics } from './hooks/useGoogleAnalytics.js'

export async function AuthLoader({params}) {
  const res = await fetcher(`/auth/${params.token}`)
  if (res.status !== 401) {
    const user = await res.json()
    if (user.token) {
      localStorage.setItem('jwt', user.token)
      localStorage.setItem('displayName', user.user_display_name)
      localStorage.setItem('expiresReadable', user.expires_readable)
      localStorage.setItem('expiresTimestamp', user.expires_timestamp)
    }
    return {authorized: true, ...user}
  } else {
    localStorage.removeItem('jwt')
    localStorage.removeItem('displayName')
    localStorage.removeItem('expiresReadable')
    localStorage.removeItem('expiresTimestamp')
    return {authorized: false}
  }
}

export function isAuthorized() {
  return (localStorage.jwt !== undefined && localStorage.jwt !== '' && localStorage.expiresTimestamp !== undefined && localStorage.expiresTimestamp >= (Date.now() / 1000))
}

export function AuthUser() {
  useGoogleAnalytics()
  const user = useLoaderData()
  return (
    <>
    { user.authorized === true ?
    <>
      <MainNavbar />
      <Box>
        <Heading>Welcome {user.user_display_name}!</Heading>
        <h2>Your access expires on {user.expires_readable}.</h2>
        <Button color="success" className="mt-4"><NavLink to={`/trends`}>Start exploring!</NavLink></Button>
      </Box>
    </>
    :
    <NoAccessPage />
    }
    </>
  )
}

export function NoAccessPage() {
  useGoogleAnalytics()
  return (
    <Box>
      Sorry, we couldn't find your auth code. It may have expired.
    </Box> 
  )
}