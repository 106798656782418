import React, { useState, useCallback, useEffect } from 'react'
import { Message, Button, Form } from 'react-bulma-components';
import RadioListControl from './RadioListControl';
import { fetcher } from './utils';

export default function SnapPoll({id, question, typeOfResponse, options, hidden, useLocalStorage}) {
  const [selectedValue, setSelectedValue] = useState(null)
  const [feedbackText, setFeedbackText] = useState(null)
  const [hidePoll, setHidePoll] = useState(hidden)

  useEffect(() => {
    if (useLocalStorage) {
      hidden = localStorage.getItem(`poll-${id}-hide`) == 'true'
    }
    setHidePoll(hidden)
  }, [useLocalStorage, hidden])

  const handleRadioListChange = useCallback((value, dataTitle, isChecked) => {
    setSelectedValue(value)
  }, [])

  const handleTextareaChange = useCallback((value) => {
    setFeedbackText(value)
  }, [])
  
  const closePoll = useCallback(() => {
    if (useLocalStorage) {
      localStorage.setItem(`poll-${id}-hide`, 'true')
    }
    setHidePoll(true)
  }, [id])
  

  const submitFeedback = useCallback(() => {
    fetcher(`/user/feedback?id=${id}&feedback=${typeOfResponse === 'multi' ? selectedValue : feedbackText}`)
    closePoll()
  }, [id, typeOfResponse, selectedValue, feedbackText, closePoll])  

  return (
    <>
    {hidePoll !== true ?
    <Message>
      <Message.Header><span>{question}</span><Button remove onClick={closePoll} /></Message.Header>
      <Message.Body>
        {typeOfResponse === 'multi' ?
          <RadioListControl title="Poll" hideTitle={false} dataTitle="poll_options" data={options} onChange={handleRadioListChange} />
        :
          <Form.Textarea onChange={(e) => handleTextareaChange(e.target.value)}></Form.Textarea>
        }
      </Message.Body>
      <Button color="primary" ml={5} mb={5} onClick={submitFeedback}>Submit</Button>
    </Message>
    : <></> }
    </>
  )
}

