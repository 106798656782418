import { useCallback, useEffect, useState } from "react";
import { Columns, Container, Tabs, Button, Notification, Modal, Message, Table } from 'react-bulma-components';
import {priceFormat, tcoCalculator, roundTwoSigs} from './utils.js'
import './CostToOwnCalculator.css';

export default function CostToOwnCalculator({purchasePrice, medianPrice, yearlyDepreciation, show, onClose}) {
  // const [purchasePrice, setPurchasePrice] = useState(0)
  // const [discountFromMedian, setDiscountFromMedian] = useState(0)
  
  const [calcs, setCalcs] = useState({})
  const [depreciationWidth, setDepreciationWidth] = useState('%')
  const [interestWidth, setInterestWidth] = useState('%')
  
  const [taxRate, setTaxRate] = useState(0.09)
  const [interestRate, setInterestRate] = useState(0.065)

  useEffect(() => {
    const thisCalcs = tcoCalculator(purchasePrice, medianPrice, yearlyDepreciation, taxRate, interestRate)
    setCalcs(thisCalcs)
    setDepreciationWidth(Math.abs(parseInt((thisCalcs.totalDepreciation3YAnnualized / (thisCalcs.tCO3YMonthly * 12)) * 100)) + '%')
    setInterestWidth(100 - Math.abs(parseInt((thisCalcs.totalDepreciation3YAnnualized / (thisCalcs.tCO3YMonthly * 12)) * 100)) + '%')
  }, [purchasePrice, medianPrice, yearlyDepreciation, taxRate, interestRate])

  return (
    <>
    <Container className={show ? "ctoc-main" : "ctoc-main is-hidden"}>
      <Message>
        <Message.Header>
          Cost to own calculator
          <Button className="delete" onClick={onClose}></Button>
        </Message.Header>
        <Message.Body>
          {/* Per month and per year */ } 
          <Columns centered="true">
            <Columns.Column style={{fontWeight: 700, fontSize: "120%"}}>
            {calcs.tCO3YMonthly < 0 ? '+' : ''}{priceFormat.format(Math.abs(calcs.tCO3YMonthly))}<br/>per month
            </Columns.Column>
            <Columns.Column style={{fontWeight: 700, fontSize: "120%"}}>
            {calcs.tCO3YMonthly < 0 ? '+' : ''}{priceFormat.format(Math.abs(calcs.tCO3YMonthly*12))}<br/>per year
            </Columns.Column>
          </Columns>
          {/* Visual bar graph of payment */ }
          {calcs.totalDepreciation3Y < 0 ? (
          <figure class="ctoc-stacked-bar">
            <div class="graphic">
              <div class="row">
                <div class="chart">
                  <span class="chart-block" title="Depreciation" style={{width: depreciationWidth}}></span>
                  <span class="chart-block" title="Finance Charge" style={{width: interestWidth}}></span>
                </div>
              </div>
            </div>
          </figure>
          ) : calcs.tCO3YMonthly < 0 ? (
            <span style={{fontSize:"85%"}}>Note: This car has <strong>positive</strong> returns.</span>
          ) : ''}
          {/* Breakdown of components towards payments */ } 
          <Columns className="bar-chart-legend-container" centered="true">
            <Columns.Column>
              <svg class="dot-color stacked-bar-chart-color-1 padding-right-small" viewBox="0 0 120 120"><circle cx="60" cy="60" r="50"></circle></svg>
              <span class="">{calcs.totalDepreciation3Y > 0 ? 'Appreciation' : 'Depreciation'}</span><br/>
              <span class="">{priceFormat.format(calcs.totalDepreciation3YAnnualized/12)}</span>
            </Columns.Column>
            <Columns.Column>
              <svg class="dot-color stacked-bar-chart-color-2 padding-right-small" viewBox="0 0 120 120"><circle cx="60" cy="60" r="50"></circle></svg>
              <span class="">Finance Charge</span><br/>
              <span class="">{priceFormat.format(calcs.simpleInterest3YAnnualized/12)}</span>
            </Columns.Column>
          </Columns>
          {/* Breakdown of total cost to own */ }
          <Table className="ctoc-breakdown mx-auto">
            <tr>
              <td>Purchase price</td>
              <td class="has-text-right">{priceFormat.format(purchasePrice)}</td>
            </tr>
            <tr>
              <td>Taxes ({roundTwoSigs(taxRate*100)}%)</td>
              <td class="has-text-right">{priceFormat.format(calcs.taxes)}</td>
            </tr>
            <tr>
              <th style={{paddingTop: "20px"}}>{yearlyDepreciation > 0 ? 'Appreciation' : 'Depreciation'} Rate</th>
              <th style={{paddingTop: "20px"}}class="has-text-right">{yearlyDepreciation}%/yr</th>
            </tr>
            <tr>
              <td>3 year {calcs.depreciation3Y > 0 ? 'appreciation' : 'depreciation'}</td>
              <td class="has-text-right">{priceFormat.format(calcs.depreciation3Y)}</td>
            </tr>
            <tr>
              <td><span class="has-tooltip-arrow has-tooltip-text-right has-tooltip-top" data-tooltip="Median minus Total Financed">{calcs.discountFromMedian > 0 ? 'Discount from Median' : 'Over Median'}</span></td>
              <td className={calcs.discountFromMedian > 0 ? 'has-text-right has-text-success' : 'has-text-right has-text-danger'}>{priceFormat.format(calcs.discountFromMedian)}</td>
            </tr>
            <tr>
              <td>Total 3 year {calcs.totalDepreciation3Y > 0 ? 'appreciation' : 'depreciation'}</td>              
              <td class="has-text-right">{priceFormat.format(calcs.totalDepreciation3Y)}</td>
            </tr>
            <tr>
              <td>Total {calcs.totalDepreciation3Y > 0 ? 'appreciation' : 'depreciation'} / year</td>
              <td className={calcs.totalDepreciation3Y >= 0 ? 'has-text-success has-text-right' : 'has-text-danger has-text-right'}>{priceFormat.format(calcs.totalDepreciation3YAnnualized)}</td>
            </tr>
            <tr>
              <th style={{paddingTop: "20px"}}>Finance Charge</th>
              <th style={{paddingTop: "20px"}} class="has-text-right">{interestRate*100}%/yr</th>
            </tr>
            <tr>
              <td>Total Financed</td>              
              <td class="has-text-right">{priceFormat.format(calcs.totalFinanced)}</td>
            </tr>
            <tr>
              <td>Simple Interest 3 years</td>
              <td class="has-text-right">{priceFormat.format(calcs.simpleInterest3Y)}</td>
            </tr>
            <tr>
              <td>Interest / year</td>
              <td class="has-text-danger has-text-right">{priceFormat.format(calcs.simpleInterest3YAnnualized)}</td>
            </tr>
          </Table>
        </Message.Body>
      </Message>
    </Container>
    </>
  )
}