import React, { useState, useEffect, useCallback } from 'react'
import SearchOptions from './SearchOptions.js';
import { Columns, Container, Tabs, Button, Heading } from 'react-bulma-components';
import DepCurveControl from './DepCurveControl.js';
import SoldReportControl from './SoldReportControl.js';
import OnSaleListings from './OnSaleListings.js';
import ByStateControl from './ByStateControl.js';
import MainNavbar from './MainNavbar.js';
import { useLoaderData, useLocation } from 'react-router-dom';
import { useGoogleAnalytics, recordGAPageview } from './hooks/useGoogleAnalytics.js'
import 'bulma/css/bulma.min.css';
const { Tab } = Tabs;

export async function CarHistoricalLoader({params}) {
  return params
}

export function CarHistoricalExplorerPage() {
  const params = useLoaderData()
  const pageTitle = `Trends for ${params.make} ${params.model}`
  useGoogleAnalytics(pageTitle)
  let location = useLocation()
  const [selectedCarId, setSelectedCarId] = useState(params.carid)
  const [selectedTabId, setSelectedTabId] = useState(1)
  const [selectedSearchOptionsUrlSlug, setSelectedSearchOptionsUrlSlug] = useState('')

  const tabs = {
    1: {title: 'Depreciation', gaSlug: '/dep-curve'},
    // {id: 2, title: 'Detailed History'},
    2: {title: 'Best Deals', gaSlug: '/best-deals'},
    3: {title: 'Price Drops', gaSlug: '/price-drops'},
    // {id: 3, title: 'Price Histogram'},
    4: {title: 'Sold Report', gaSlug: '/sold-report'},
    5: {title: 'By State', gaSlug: '/by-state'},
  }

  const handleClick = useCallback((tabId) => {
    setSelectedTabId(tabId)
    recordGAPageview(`${pageTitle} | ${tabs[tabId].title}`, `${location.pathname}${tabs[tabId].gaSlug}`, window.location.href)
  }, [pageTitle, location])

  return (
    <>
    <MainNavbar />
    {/* <Container>
      <CarSelectorSearch
        carId={this.state.selectedCarId}
        onChange={(value) => {
          this.setState({selectedCarId: value, selectedTabId: 0, selectedSearchOptionsUrlSlug: ''})
      }} />
    </Container> */}
    <Container className="main">
      <Columns centered="false">
        <Columns.Column size={2}>
          <Heading size={5}>{params.make} {params.model}</Heading>
          <SearchOptions carId={selectedCarId} onChange={(urlSlug) => setSelectedSearchOptionsUrlSlug(urlSlug)} />
        </Columns.Column>
        <Columns.Column className="main-body" style={{backgroundColor: '#fff', borderLeft: '1px solid #dfdfdf'}}>
          <div className="buttons has-addons is-centered" style={{marginBottom: 0}}>
            {Object.entries(tabs).map(([key, item]) => {
              key = parseInt(key)
              return (
              <Button
                id={key}
                color={selectedTabId === key ? 'info' : ''}
                isSelected={selectedTabId === key}
                // onClick={() => { handleClick(key, item.gaSlug, `${pageTitle} | ${item.title}`) }}
                onClick={() => { handleClick(key) }}
                style={{paddingTop: "4px", paddingBottom: "4px", paddingLeft: "8px", paddingRight: "8px", fontSize: "90%"}}
              >
                {item.title}
              </Button>
              )
            })}
          </div>
          <DepCurveControl id="dep-curve" isActive={selectedTabId === 1} selectedCarId={selectedCarId} selectedSearchOptionsUrlSlug={selectedSearchOptionsUrlSlug} onClickSeeMoreListings={handleClick} />
          <OnSaleListings id="best-deals" isActive={selectedTabId === 2} selectedCarId={selectedCarId} selectedSearchOptionsUrlSlug={selectedSearchOptionsUrlSlug} pageType="best-deals" />
          <OnSaleListings id="price-drops" isActive={selectedTabId === 3} selectedCarId={selectedCarId} selectedSearchOptionsUrlSlug={selectedSearchOptionsUrlSlug} pageType="price-drops" />
          <SoldReportControl id="sold-report" isActive={selectedTabId === 4} selectedCarId={selectedCarId} selectedSearchOptionsUrlSlug={selectedSearchOptionsUrlSlug} />
          <ByStateControl id="by-state" isActive={selectedTabId === 5} selectedCarId={selectedCarId} selectedSearchOptionsUrlSlug={selectedSearchOptionsUrlSlug} />
          {/* <DetailedHistoryControl id="detailed-history" isActive={this.state.selectedTabId === 2} selectedCarId={this.state.selectedCarId} selectedSearchOptionsUrlSlug={this.state.selectedSearchOptionsUrlSlug} /> */}
        </Columns.Column>
      </Columns>
    </Container>
    </>
  )
}