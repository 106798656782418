import { useEffect, useState } from "react";
import { fetcher } from "./utils";
import { Container, Box } from 'react-bulma-components'


export default function UserSearchesPage() {
  const [apiData, setApiData] = useState(null)

  useEffect(() => {
    fetcher('/user/searches')
      .then((res) => res.json())
      .then((json) => {
        setApiData(json)
      })
  }, [])

  return (
    <Container>
      <Box>
      <ul>
      {apiData && apiData.map((item) => {
        return (
          <li key={item.carId}>{item.name} (count = {item.count})</li>
          )
        })}
      </ul>
      </Box>
    </Container>
  )
}