import React, { useState, useEffect, useCallback } from 'react'
import { fetcher, log } from './utils.js'
import { Columns, Container, Box } from 'react-bulma-components'
import MainNavbar from './MainNavbar'
import CarCard from './CarCard.js'
import CostToOwnCalculator from './CostToOwnCalculator.js';
import { useGoogleAnalytics, recordGACustom } from './hooks/useGoogleAnalytics.js'

export default function TrackedCarsPage() {
  useGoogleAnalytics('Tracked Cars')
  const [apiData, setApiData] = useState(null)
  const [shouldShowTCOCalculator, setShouldShowTCOCalculator] = useState(false)
  const [purchasePrice, setPurchasePrice] = useState(0)
  const [medianPrice, setMedianPrice] = useState(0)
  const [yearlyDepreciation, setYearlyDepreciation] = useState(0)

  useEffect(() => {
    fetcher(`/user/get-tracked-cars`)
      .then((res) => res.json())
      .then((json) => {
        setApiData(json)
      })
  }, [])

  const handleTracking = (startTracking, vin, listingId) => {
    log({startTracking})
    if (startTracking) {
      fetcher(`/user/track-car/${vin}/${listingId}`)
    } else {
      fetcher(`/user/track-car/archive/${vin}`)
    }
  }

  const showTCOCalculator = (carPrice, medianPrice, yearlyDepreciation, link, make, model) => {
    console.log({carPrice, medianPrice, yearlyDepreciation})
    recordGACustom('show_tco_calculator', {
      link: link,
      make: make,
      model: model,
      price: carPrice,
    })
    setPurchasePrice(carPrice)
    setMedianPrice(medianPrice)
    setYearlyDepreciation(yearlyDepreciation)
    setShouldShowTCOCalculator(true)
  }

  return (
    <>
    <MainNavbar />
    <Container>
      <div>
        <Box className={apiData === null || (apiData && apiData.length === 0) ? '' : 'is-hidden'}>
          You aren't tracking any cars. Click on the star on the top right of a listing to start tracking it.
        </Box>
        <Columns multiline="true">
        {apiData && apiData.map((item) => {
          console.log({item})
          return (
            <CarCard
              key={item.vin}
              carData={item.listing}
              isSold={item.sold}
              showDealScore={false}
              showPriceDrop={true}
              onClickTrackCar={(startTracking) => handleTracking(startTracking, item.vin, item.id)}
              onClickTCOCalculator={() => showTCOCalculator(item.listing.price, item.listing.medianPrice, item.listing.yearlyDepreciation, item.link, item.make, item.model)}
            />
            )
          })}
        </Columns>
      </div>
      {apiData && apiData.length > 0 ? (
        <CostToOwnCalculator
          purchasePrice={purchasePrice}
          medianPrice={medianPrice}
          yearlyDepreciation={yearlyDepreciation}
          show={shouldShowTCOCalculator}
          onClose={() => setShouldShowTCOCalculator(false)}></CostToOwnCalculator>
      ) : ''}
    </Container>
    </>
  )
}