import React, { useState, useEffect, useCallback } from 'react'
import 'bulma/css/bulma.min.css';
import { fetcher } from './utils.js'
import MainNavbar from './MainNavbar.js';
import { Table, Form, Button } from 'react-bulma-components';
const { Input } = Form;

const CarSourceManagementPage = ({carId, onChange}) => {
  const [apiData, setApiData] = useState([])

  useEffect(() => {
    fetcher("/api/cars_sources")
      .then((res) => res.json())
      .then((json) => {
        setApiData(json)
      })
  }, [])

  return (
    <>
    <MainNavbar />
    <div className="main">
      <Table hoverable="true" size="fullwidth">
        <thead>
          <tr>
            <th>ID</th>
            <th>Car ID</th>
            <th>Source Name</th>
            <th>URL</th>
            <th>Metadata</th>
            <th>Make ID</th>
            <th>Model ID</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
        {apiData.map((item) => {
          return (
            <tr>
              <td>{item.id}</td>
              <td><Input value={item.car_id} /></td>
              <td><Input value={item.source_name} /></td>
              <td><Input value={item.url} /></td>
              <td><Input value={item.extra_metadata} /></td>
              <td><Input value={item.make_id} /></td>
              <td><Input value={item.model_id} /></td>
              <td>
                <Button.Group>
                  <Button color="primary" renderAs="span" size="small" className="is-light">Update</Button>
                  <Button color="danger" renderAs="span" size="small" className="is-light">Delete</Button>
                </Button.Group>
              </td>
            </tr>
          )
        })}
        </tbody>
      </Table>
    </div>
    </>
  )
}

export default CarSourceManagementPage