export default function DealScore({dealRating, normalizedScore, reasons}) {
  let colorClass = ''
  if (dealRating === 'Outstanding' || dealRating === 'Great') {
    colorClass = 'has-text-success'
  } else if (dealRating === 'Average') {
    colorClass = 'has-text-warning-dark'
  } else if (dealRating === 'Poor') {
    colorClass = 'has-text-danger'
  }

  const className = `${colorClass} has-text-right is-uppercase`

  return (
    <>
    <p style={{fontSize: "60%"}} className="has-text-right is-uppercase">Car Scout Score</p>
    <p style={{fontSize: "90%", fontWeight: 600}} className={className} data-score={normalizedScore}>
      {reasons && reasons.length > 0 ?
        <span class="has-tooltip-arrow has-tooltipl-multiline has-tooltip-text-right has-tooltip-left" data-tooltip={reasons.join(`\u000A`)}>{dealRating}</span>
      : dealRating}
    </p>
    </>
  )
}