import { Card, Media, Heading, Columns, Content } from 'react-bulma-components'
import React, { useState } from 'react'
import {priceFormat, tcoCalculator} from './utils.js'
import DealScore from './DealScore.js';
import { recordGACustom } from './hooks/useGoogleAnalytics.js'
import './CarCard.css';

const CarCard = ({carData, isSold, onClickTrackCar, onClickTCOCalculator, previewMode = false, onClickCard, showDealScore = true, showPriceDrop = false}) => {
  const [isTracked, setIsTracked] = useState(carData.is_tracked)

  const deltaToMedianPrice = parseInt(((carData.price / carData.medianPrice) - 1) * 100)
  const deltaToMedianMiles = parseInt(((carData.miles / carData.medianMiles) - 1) * 100)
  const deltaToMedianDom = parseInt(((carData.days_on_market / carData.medianDom) - 1) * 100)

  const TAX_RATE = 0.09
  const INTEREST_RATE = 0.065

  const calcs = tcoCalculator(carData.price, carData.medianPrice, carData.yearlyDepreciation, TAX_RATE, INTEREST_RATE)

  let hasCargurusDealInfo = carData.cargurus_deal_info !== '0'
  const cargurusDealInfo = hasCargurusDealInfo && JSON.parse(carData.cargurus_deal_info)
  if (cargurusDealInfo === null || cargurusDealInfo.deal_rating === 'NA') {
    hasCargurusDealInfo = false
  }

  return (
    <Columns.Column desktop={{size: previewMode ? '2' : 'one-third'}} tablet={{size: 'half'}} onClick={() => {if (previewMode) onClickCard()}}>
        <Card data-listings-id={carData.id} className={previewMode ? 'is-preview' : ''}>
          <Card.Image size="4by3" src={carData.thumbnail ? carData.thumbnail : '/missing-car.jpeg'} className={carData.price === null ? "is-disabled" : ""} />
          <span className={previewMode ? 'is-hidden' : 'icon icon-track has-tooltip-arrow has-tooltipl-multiline has-tooltip-text-right has-tooltip-left'} data-tooltip={isTracked ? 'Stop tracking' : 'Track this car'}>
            <i className={isTracked ? 'mdi mdi-star mdi-24px mdi-light' : 'mdi mdi-star-outline mdi-24px mdi-light'}
                onClick={() => {
                  recordGACustom(isTracked ? 'stop_tracking_car' : 'start_tracking_car', {
                    link: carData.link,
                    make: carData.make,
                    model: carData.model,
                    price: carData.price,
                  })
                  setIsTracked(!isTracked)
                  onClickTrackCar(!isTracked)
                }}></i>
          </span>
          <Card.Content>
            <Media>
              <Media.Item>
                <Columns className="is-vcentered">
                  <Columns.Column size={previewMode ? 'full' : 'half'}>
                    {isSold === true ? (
                      <Heading size={previewMode ? 6 : 4} className="">Sold</Heading>
                    ) : (
                      <>
                      <Heading size={previewMode ? 6 : 4} className="" style={{marginBottom: "0.4rem"}}>{priceFormat.format(carData.price)}</Heading>
                      {calcs.tCO3YMonthly && !previewMode ? <span onClick={onClickTCOCalculator} class="car-card-monthly has-tooltip-arrow has-tooltipl-multiline has-tooltip-text-right has-tooltip-left" data-tooltip="Open Calculator">{calcs.tCO3YMonthly < 0 ? '+' : ''}{priceFormat.format(Math.abs(calcs.tCO3YMonthly))}<span style={{fontSize:"80%"}}> per month</span></span> : ''}
                      </>
                    )}
                  </Columns.Column>
                  <Columns.Column size={previewMode ? 'full' : 'half'} className={(carData.carscout_deal_score || carData.lastMonthPriceDrop) && isSold !== true ? '' : 'is-hidden'}>
                    {showDealScore ? (
                      <DealScore dealRating={carData.carscout_deal_score && carData.carscout_deal_score.dealRating} reasons={carData.carscout_deal_score && carData.carscout_deal_score.reasons} normalizedScore={carData.carscout_deal_score && carData.carscout_deal_score.normalizedScore} />
                      ) : ''}
                    {showPriceDrop ? (
                      <>
                      <p style={{fontSize: "60%"}} className="has-text-right is-uppercase">1 month Price Drop</p>
                      <p style={{fontSize: "90%", fontWeight: 600}} className="has-text-right has-text-success">{priceFormat.format(carData.lastMonthPriceDrop*-1)}</p>
                      </>
                      ) : ''}
                  </Columns.Column>
                </Columns>
                <Heading subtitle size={6} className={previewMode ? 'is-hidden' : ''}>
                  <a href={carData.link} target="_blank" rel="noreferrer"
                    onClick={() => recordGACustom('ext_link_click', {
                      link: carData.link,
                      make: carData.make,
                      model: carData.model,
                      price: carData.price,
                    })}
                    >
                  <span className="icon-text">
                    {carData.model_year} {carData.make} {carData.model} {carData.trim}
                    <span className="icon" >
                      <i className="mdi mdi-open-in-new"></i>
                    </span>
                  </span>
                  </a>
                  <p style={{fontSize: "80%"}} className="has-text-danger">
                  {carData.is_salvage_title === 1 ?
                    <>
                    <span className="icon-text">
                      <span className="icon" >
                        <i className="mdi mdi-alert"></i>
                      </span>
                      Salvage Title
                    </span><br/>
                    </>
                  : ''}
                  {carData.is_lemon === 1 ?
                    <>
                    <span className="icon-text">
                      <span className="icon" >
                        <i className="mdi mdi-alert"></i>
                      </span>
                      Listed as Lemon
                    </span><br/>
                    </>
                  : ''}
                  {carData.accident_count > 0 ?
                    <>
                    <span className="icon-text">
                      <span className="icon" >
                        <i className="mdi mdi-alert"></i>
                      </span>
                      Been in an accident
                    </span><br/>
                    </>
                  : ''}
                  </p>
                </Heading>
              </Media.Item>
            </Media>
            <Content style={{fontSize: "90%"}} className={previewMode ? 'is-hidden' : ''}>
              <Columns>
                <Columns.Column>
                  {carData.miles} mi<br/>
                  {carData.days_on_market} Days on market
                </Columns.Column>
                <Columns.Column>
                  Seller State: {carData.seller_state}<br/>
                  <a href={'/vin/' + carData.vin} target="_blank">Listing history</a>
                </Columns.Column>
              </Columns>
              <table className="median-table">
                <thead>
                  <tr>
                    <th>Under</th>
                    <th>Median</th>
                    <th>Over</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="has-text-success">{deltaToMedianPrice < 0 ? '-$'+parseInt(Math.abs((carData.price - carData.medianPrice)/1000))+'K ('+deltaToMedianPrice+'%)' : ''}</td>
                    <td>${parseInt(carData.medianPrice/1000)}K</td>
                    <td className="has-text-danger">{deltaToMedianPrice > 0 ? '+$'+parseInt((carData.price - carData.medianPrice)/1000)+'K ('+deltaToMedianPrice+'%)' : ''}</td>
                  </tr>
                  <tr>
                    <td className="has-text-success">{deltaToMedianMiles < 0 ? parseInt((carData.miles - carData.medianMiles)/1000)+'K mi ('+deltaToMedianMiles+'%)' : ''}</td>
                    <td>{parseInt(carData.medianMiles)} mi</td>
                    <td className="has-text-danger">{deltaToMedianMiles > 0 ? '+'+parseInt((carData.miles - carData.medianMiles)/1000)+'K mi ('+deltaToMedianMiles+'%)' : ''}</td>
                  </tr>
                  <tr>
                    <td className="has-text-success">{deltaToMedianDom < 0 ? carData.days_on_market+' ('+deltaToMedianDom+'%)' : ''}</td>
                    <td>{parseInt(carData.medianDom)} days</td>
                    <td className="has-text-danger">{deltaToMedianDom > 0 ? carData.days_on_market+' ('+deltaToMedianDom+'%)' : ''}</td>
                  </tr>
                </tbody>
              </table>
              {/* <p className={hasCargurusDealInfo ? '' : 'is-invisible'}>
                Deal Rating: {cargurusDealInfo && cargurusDealInfo.deal_rating}<br />
                Price Under Market: {cargurusDealInfo && cargurusDealInfo.price_under_market}<br />
              </p> */}
            </Content>
          </Card.Content>
        </Card>
    </Columns.Column>
  )
}

export default CarCard
