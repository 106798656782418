import { fetcher, log } from './utils.js'
import React, { useState, useEffect, useRef } from 'react'
import { Columns, Heading, Dropdown } from 'react-bulma-components';
import CarCard from './CarCard.js';
import CostToOwnCalculator from './CostToOwnCalculator.js';
import { recordGACustom } from './hooks/useGoogleAnalytics.js'

const OnSaleListings = ({id, isActive, selectedCarId, selectedSearchOptionsUrlSlug, maxNumberToShow = 60, pageType = 'best-deals', onClickSeeMoreListings}) => {
  const [apiData, setApiData] = useState({listings: []})
  const [apiCallComplete, setApiCallComplete] = useState(false)
  const [purchasePrice, setPurchasePrice] = useState(0)
  const [medianPrice, setMedianPrice] = useState(0)
  const [yearlyDepreciation, setYearlyDepreciation] = useState(0)
  const [shouldShowTCOCalculator, setShouldShowTCOCalculator] = useState(false)
  const [localPageType, setLocalPageType] = useState(pageType)
  const [sortByDropdownLabel, setSortByDropdownLabel] = useState('Sort by: Score')

  const PAGE_TYPE_CONFIG = {
    'best-deals': {
      apiUrl: `/db/get-listings/${selectedCarId}/${selectedSearchOptionsUrlSlug}`,
      previewMode: false,
      headerText: 'Best Deals',
      noResultsText: 'No good deals found.',
      showDealScore: true,
      showPriceDrop: false,
      showSortByDropdown: true,
      sortByDropdownLabel: 'Sort by: Score',
    },
    'best-deals-preview': {
      apiUrl: `/db/get-listings/${selectedCarId}/${selectedSearchOptionsUrlSlug}`,
      previewMode: true,
      headerText: 'Best Deals',
      parentPageTabId: 2,
      showDealScore: true,
      showPriceDrop: false,
      showSortByDropdown: false,
    },
    'price-drops': {
      apiUrl: `/db/get-listings-by-price-drops/${selectedCarId}/${selectedSearchOptionsUrlSlug}`,
      previewMode: false,
      headerText: 'Price Drops',
      noResultsText: 'No significant price drops in the last month.',
      showDealScore: false,
      showPriceDrop: true,
      showSortByDropdown: false,
    },
    'price-drops-preview': {
      apiUrl: `/db/get-listings-by-price-drops/${selectedCarId}/${selectedSearchOptionsUrlSlug}`,
      previewMode: true,
      headerText: 'Price Drops',
      parentPageTabId: 3,
      showDealScore: false,
      showPriceDrop: true,
      showSortByDropdown: false,
    },
    'lowest-price': {
      apiUrl: `/db/get-listings/${selectedCarId}/${selectedSearchOptionsUrlSlug}&sort=lowest-price`,
      previewMode: false,
      headerText: 'Best Deals',
      noResultsText: 'No good deals found.',
      showDealScore: true,
      showPriceDrop: false,
      showSortByDropdown: true,
      sortByDropdownLabel: 'Sort by: Price',
    },
    'lowest-miles': {
      apiUrl: `/db/get-listings/${selectedCarId}/${selectedSearchOptionsUrlSlug}&sort=lowest-miles`,
      previewMode: false,
      headerText: 'Best Deals',
      noResultsText: 'No good deals found.',
      showDealScore: true,
      showPriceDrop: false,
      showSortByDropdown: true,
      sortByDropdownLabel: 'Sort by: Miles',
    },
  }
  
  useEffect(() => {
    if (isActive && selectedSearchOptionsUrlSlug && selectedSearchOptionsUrlSlug !== '') {
      // fetcher(`/db/get-listings/${selectedCarId}/${selectedSearchOptionsUrlSlug}`)
      // fetcher(`/db/get-listings-by-price-drops/${selectedCarId}/${selectedSearchOptionsUrlSlug}`)
      fetcher(PAGE_TYPE_CONFIG[localPageType].apiUrl)
        .then((res) => res.json())
        .then((json) => {
          setApiData(json)
          setApiCallComplete(true)
        })
    }
  }, [isActive, selectedCarId, selectedSearchOptionsUrlSlug, localPageType])

  const handleTracking = (startTracking, vin, listingId) => {
    log({startTracking})
    if (startTracking) {
      fetcher(`/user/track-car/${vin}/${listingId}`)
    } else {
      fetcher(`/user/track-car/archive/${vin}`)
    }
  }

  const handleSortByDropdownChange = (selectedValue) => {
    console.log({selectedValue})
    setLocalPageType(selectedValue)
    setSortByDropdownLabel(PAGE_TYPE_CONFIG[selectedValue].sortByDropdownLabel)
    console.log(PAGE_TYPE_CONFIG[selectedValue])
  }

  const showTCOCalculator = (carPrice, medianPrice, yearlyDepreciation, link, make, model) => {
    recordGACustom('show_tco_calculator', {
      link: link,
      make: make,
      model: model,
      price: carPrice,
    })
    setPurchasePrice(carPrice)
    setMedianPrice(medianPrice)
    setYearlyDepreciation(yearlyDepreciation)
    setShouldShowTCOCalculator(true)
  }

  const isHidden = isActive ? "" : "is-hidden"
  return (
    <div id={id} className={isHidden}>
      {apiData.listings.length > 0 && PAGE_TYPE_CONFIG[localPageType].previewMode ? (
      <Heading size={5}>{PAGE_TYPE_CONFIG[localPageType].headerText} <a onClick={() => { window.scrollTo(0, 0); onClickSeeMoreListings(PAGE_TYPE_CONFIG[localPageType].parentPageTabId) }} style={{marginLeft: "20px", fontSize: "80%"}}>More »</a></Heading>
      ) : ''}
      {PAGE_TYPE_CONFIG[localPageType].showSortByDropdown ? 
      <Dropdown hoverable={true} closeOnSelect={true} color="" icon={<span className="icon" ><i className="mdi mdi-menu-down"></i></span>} label={sortByDropdownLabel} onChange={handleSortByDropdownChange} style={{marginBottom:"20px"}}>
        <Dropdown.Item renderAs="a" value="best-deals">Score</Dropdown.Item>
        <Dropdown.Item renderAs="a" value="lowest-price">Price</Dropdown.Item>
        <Dropdown.Item renderAs="a" value="lowest-miles">Miles</Dropdown.Item>
      </Dropdown>
      : ''}
      <Columns multiline="true">
      {apiData.listings.slice(0, maxNumberToShow).map((item) => {
        return (
          <CarCard
            key={item.vin}
            carData={item}
            onClickTrackCar={(startTracking) => handleTracking(startTracking, item.vin, item.id)}
            onClickTCOCalculator={() => showTCOCalculator(item.price, item.medianPrice, item.yearlyDepreciation, item.link, item.make, item.model)}
            showDealScore={PAGE_TYPE_CONFIG[localPageType].showDealScore}
            showPriceDrop={PAGE_TYPE_CONFIG[localPageType].showPriceDrop}
            previewMode={PAGE_TYPE_CONFIG[localPageType].previewMode}
            onClickCard={() => { window.scrollTo(0, 0); onClickSeeMoreListings(PAGE_TYPE_CONFIG[localPageType].parentPageTabId) }}
          />
          )
      })}
      </Columns>
      {apiData.listings.length > 0 && !PAGE_TYPE_CONFIG[localPageType].previewMode ? (
        <CostToOwnCalculator
            purchasePrice={purchasePrice}
            medianPrice={medianPrice}
            yearlyDepreciation={yearlyDepreciation}
            show={shouldShowTCOCalculator}
            onClose={() => setShouldShowTCOCalculator(false)}></CostToOwnCalculator>
      ) : ''}
      {apiCallComplete && apiData.listings.length <= 0 && !PAGE_TYPE_CONFIG[localPageType].previewMode ? <Heading size={5}>{PAGE_TYPE_CONFIG[localPageType].noResultsText}</Heading> : ''}
    </div>
  )
}

export default OnSaleListings

