import React, { useState, useEffect, useCallback } from 'react'
import Chart from './Chart.js'
import {fetcher} from './utils.js'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { useGoogleAnalytics } from './hooks/useGoogleAnalytics.js'

export default function MacroTrendsPage() {
  useGoogleAnalytics('Macro Trends')
  const [apiData, setApiData] = useState(null)

  useEffect(() => {
    fetcher(`/db/get-macro-trends/?makes=Porsche,BMW,Tesla,Ferrari`)
      .then((res) => res.json())
      .then((json) => {
        setApiData(json)
      })
  }, [])

  const setupAxesAndSeries = useCallback((chart) => {
    let valueAxis_prices = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis_prices.renderer.minGridDistance = 50;
    
    for (let t of apiData.makes) {
      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = t;
      series.name = t;
      series.yAxis = valueAxis_prices
      series.dataFields.dateX = "date";
      series.tooltipText = "[#000]{valueY.value}[/]";
      series.tooltip.background.fill = am4core.color("#FFF");
      series.tooltip.label.fill = am4core.color("#00");
      series.tooltip.getFillFromObject = false;
      series.strokeWidth = 2;
      series.tensionX = 0.77;
      series.adapter.add("tooltipText", function(ev) {
        let text = "[bold]{dateX}[/]\n"
        chart.series.each(function(item) {
          if (!item.isHidden && item.dataFields.valueY != null) {
            if (item.name.includes('doms')) {
            } else {
              text += "[" + item.stroke.hex + "]●[/] " + item.name + ": {" + item.dataFields.valueY + "} ({" + item.dataFields.yoyDepValue + "}% YoY) ({" + item.name.substring(0,4) + "-doms} DOM)\n";
            }
          }
        });
        return text;
      });
      // Prevent cross-fading of tooltips
      series.tooltip.defaultState.transitionDuration = 0;
      series.tooltip.hiddenState.transitionDuration = 0;
    }
  }, [apiData])

  return (
    <>
    <Chart isActive={apiData !== null} setupAxesAndSeries={(chart) => setupAxesAndSeries(chart)} chartData={apiData && apiData.weeklyData} />
    </>
  )
}