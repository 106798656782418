export function roundTwoSigs(num) {
  return Number(Math.round(num+'e2')+'e-2');  
}

export const priceFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
})

export function fetcher(url, options) {
  return fetch(dashboardApiUrl(url), updateOptions(options))
}

const dashboardApiUrl = (url) => {
  return `${process.env.REACT_APP_DASHBOARD_API_BASE_URI}${url}`
}

const updateOptions = (options) => {
  const update = { ...options }
  if (localStorage.jwt) {
    update.headers = {
      ...update.headers,
      Authorization: `Bearer ${localStorage.jwt}`,
    }
  }
  return update
}

export function log(...args) {
  if (process.env.REACT_APP_SHOW_DEBUG_LOGS && process.env.REACT_APP_SHOW_DEBUG_LOGS === 'true') {
    console.log(...args)
  }
}

export function tcoCalculator(purchasePrice, medianPrice, yearlyDepreciation, taxRate, interestRate) {
  let retVal = {}
  retVal.taxes = purchasePrice * taxRate
  retVal.totalFinanced = purchasePrice + (purchasePrice * taxRate)
  retVal.discountFromMedian = medianPrice - retVal.totalFinanced

  retVal.depreciation3Y = retVal.totalFinanced * yearlyDepreciation/100 * 3
  retVal.totalDepreciation3Y = (retVal.totalFinanced * yearlyDepreciation/100 * 3) + retVal.discountFromMedian
  retVal.totalDepreciation3YAnnualized = retVal.totalDepreciation3Y / 3
  
  retVal.simpleInterest3Y = retVal.totalFinanced * interestRate * 3 * -1
  retVal.simpleInterest3YAnnualized = retVal.simpleInterest3Y / 3

  const local_tco3YAnnualized = (retVal.totalDepreciation3YAnnualized + retVal.simpleInterest3YAnnualized)*-1
  retVal.tCO3YMonthly = local_tco3YAnnualized/12
  
  return retVal
}