import React, { useState, useEffect, useCallback } from 'react'
import Chart from './Chart.js'
import {roundTwoSigs, fetcher} from './utils.js'
import { Columns, Notification, Heading } from 'react-bulma-components';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import OnSaleListings from './OnSaleListings.js';

const DepCurveControl = ({id, isActive, selectedCarId, selectedSearchOptionsUrlSlug, onClickSeeMoreListings}) => {
  const [apiData, setApiData] = useState(null)
  const [shouldRenderOnSaleListings, setShouldRenderOnSaleListings] = useState(false)

  useEffect(() => {
    if (isActive && selectedSearchOptionsUrlSlug && selectedSearchOptionsUrlSlug !== '') {
      fetcher(`/db/get-dep-curve-data/${selectedCarId}/${selectedSearchOptionsUrlSlug}&range=1`)
        .then((res) => res.json())
        .then((json) => {
          json = processData(json)
          setApiData(json)
          setShouldRenderOnSaleListings(true)
        })
    }
  }, [isActive, selectedCarId, selectedSearchOptionsUrlSlug])

  const setupAxesAndSeries = useCallback((chart) => {
    let valueAxis_prices = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis_prices.renderer.minGridDistance = 50;
    valueAxis_prices.fontSize = "12px";
    
    let valueAxis_doms = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis_doms.syncWithAxis = chart.yAxes.getIndex(0)
    valueAxis_doms.renderer.opposite = true;
    valueAxis_doms.fontSize = "12px";
    
    for (let t of apiData.modelYears) {
      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = `${t}-prices`;
      series.name = `${t}-prices`;
      series.yAxis = valueAxis_prices
      series.dataFields.dateX = "date";
      series.dataFields.yoyDepValue = `${t}-yoy-dep`;
      series.tooltipText = "[#000]{valueY.value}[/]";
      series.tooltip.background.fill = am4core.color("#FFF");
      series.tooltip.label.fill = am4core.color("#00");
      series.tooltip.getFillFromObject = false;
      series.strokeWidth = 2;
      series.tensionX = 0.77;
      series.fontSize = "12px";
      series.adapter.add("tooltipText", function(ev) {
        let text = "[bold]{dateX}[/]\n"
        chart.series.each(function(item) {
          if (!item.isHidden && item.dataFields.valueY != null) {
            if (item.name.includes('doms')) {
            } else {
              text += "[" + item.stroke.hex + "]●[/] " + item.name + ": {" + item.dataFields.valueY + "} ({" + item.dataFields.yoyDepValue + "}% YoY) ({" + item.name.substring(0,4) + "-doms} DOM)\n";
            }
          }
        });
        return text;
      });
      // Prevent cross-fading of tooltips
      series.tooltip.defaultState.transitionDuration = 0;
      series.tooltip.hiddenState.transitionDuration = 0;
      
      let series2 = chart.series.push(new am4charts.LineSeries());
      series2.dataFields.dateX = "date";
      series2.dataFields.domValue = `${t}-doms`;
      series2.dataFields.openValueY = `${t}-doms-open`;
      series2.dataFields.valueY = `${t}-doms-close`;
      series2.name = `${t}-doms`;
      series2.sequencedInterpolation = true;
      series2.fillOpacity = 0.1;
      series2.tensionX = 0.8;
      series2.strokeWidth = 0;
      series2.strokeOpacity = 0;
      series2.fontSize = "12px";
      
      // let series3 = chart.series.push(new am4charts.OHLCSeries());
      // series3.dataFields.dateX = "date";
      // series3.name = `${t}-high-low`;
      // series3.dataFields.valueY = `${t}-lowest-price`;
      // series3.dataFields.openValueY = `${t}-highest-price`;
      // series3.dataFields.lowValueY = `${t}-lowest-price`;
      // series3.dataFields.highValueY = `${t}-highest-price`;
      // series3.strokeWidth = 1;
      // series3.hide()
    }

    chart.legend.itemContainers.template.events.on("over", function(e) {
      let itemContainer = e.target;
    
      // As series list is data of a legend, dataContext is series
      let series = itemContainer.dataItem.dataContext;
      let seriesTagYear = series.dataFields.valueY.substring(0,4)
      
      chart.series.each(function(chartSeries) {
        let chartSeriesTagYear = chartSeries.dataFields.valueY.substring(0,4)
        if (chartSeries.dataFields.valueY.includes('-lowest-price')) {
          return
        }
        if (chartSeries != series && chartSeriesTagYear != seriesTagYear) {
          chartSeries.segments.template.strokeOpacity = 0.15
          chartSeries.segments.template.fillOpacity = 0
        } else {
          if (!chartSeries.dataFields.valueY.includes('-doms')) {
            chartSeries.segments.template.strokeWidth = 3
          } else {
            chartSeries.segments.template.fillOpacity = 0.4
          }
        }
      })
    });
    
    // When legend item container is unhovered, make all series as they are
    chart.legend.itemContainers.template.events.on("out", function(e) {
      chart.series.each(function(chartSeries) {
        if (chartSeries.dataFields.valueY.includes('-lowest-price')) {
          return
        }
        if (chartSeries.dataFields.valueY.includes('-doms')) {
          chartSeries.segments.template.strokeOpacity = 1
          chartSeries.segments.template.strokeWidth = 0.1
          chartSeries.segments.template.fillOpacity = 0.1
        } else {
          chartSeries.segments.template.strokeOpacity = 1
          chartSeries.segments.template.strokeWidth = 2
        }
      });
    });
  }, [apiData])

  return (
    <>
    {isActive ?
    <Columns centered="true" style={{marginTop: 12}}>
      <Columns.Column size={9} style={{paddingLeft: 0, paddingRight: 0}}>
        <Chart id={id} isActive={isActive && (apiData !== null)} setupAxesAndSeries={(chart) => setupAxesAndSeries(chart)} chartData={apiData && apiData.weeklyData} />
      </Columns.Column>
      <Columns.Column size={3} style={{paddingLeft: 0, marginLeft: -30}}>
        <Notification style={{padding: "9px"}} className={isActive && (apiData !== null) ? 'is-size-7' : 'is-size-7 is-hidden'}>
          <p>The depreciation curve shows how much value a car loses over time. Buy when the curve flattens to minimize car ownership cost.</p>
          <p style={{marginTop: 8}}>The Days on Market (DOMs) shaded band shows how long cars are taking to sell. A wider band means cars are taking longer to sell, which could signal upcoming price drops.</p>
          <p style={{marginTop: 8}} className="is-flex is-align-items-center"><img alt="Tip" src="/tip-58.png" style={{width: 24, marginRight: "4px"}} />Tip: Hover over a model year at the bottom of the chart to isolate its trend.</p>
          {/* <p style={{marginTop: 4}} className="is-flex is-align-items-center"><img alt="Tip" src="/tip-58.png" style={{width: 32}} />Tip: Hovering over the chart will show the YoY depreciation.</p> */}
        </Notification>
        <table className={isActive && (apiData !== null) ? 'table is-hoverable' : 'table is-hoverable is-hidden'} style={{marginLeft: 'auto', marginRight: 'auto', fontSize: "12px"}}>
          <thead>
            <tr>
              <th></th>
              <th>2 year dep.</th>
              <th>1 year dep.</th>
              {/* <th>1 year forecast</th> */}
              <th>1 year forecast v2</th>
              <th>Recent Trend</th>
            </tr>
          </thead>
          <tbody>
            {apiData && apiData.depSummary && apiData.depSummary.map((item) => {
              let trendColor = "gray"
              let rotation = ""
              if (item.trend) {
                switch (item.trend.direction) {
                  case -2: trendColor = "red"; rotation = "mdi-rotate-45"; break;
                  case -1: trendColor = "orange"; rotation = "mdi-rotate-45"; break;
                  case 1: trendColor = "green"; rotation = "mdi-rotate-315"; break;
                  case 2: trendColor = "darkgreen"; rotation = "mdi-rotate-315"; break;
                  default: trendColor = "gray"; rotation = ""; break;
                }
              }
              return (
                <tr class="has-text-centered">
                  <td>{item.modelYear}</td>
                  <td>{item.dep2Year ? (item.dep2Year < 50 ? item.dep2Year + '%' : 'N/A') : 'N/A'}</td>
                  <td>{item.dep1Year ? (item.dep1Year < 50 ? item.dep1Year + '%' : 'N/A') : 'N/A'}</td>
                  {/* <td className={item.forecast1Year && item.forecast1Year < 50 ? (item.forecast1Year >= 0 ? 'has-text-success' : 'has-text-danger') : ''}>{item.forecast1Year ? (item.forecast1Year < 50 ? item.forecast1Year + '%' : 'N/A') : 'N/A'}</td> */}
                  <td className={item.forecast1Year_v2 && item.forecast1Year_v2 < 50 ? (item.forecast1Year_v2 >= 0 ? 'has-text-success' : 'has-text-danger') : ''}>{item.forecast1Year_v2 ? (item.forecast1Year_v2 < 50 ? item.forecast1Year_v2 + '%' : 'N/A') : 'N/A'}</td>
                  <td>
                    {/* <span className="icon has-tooltip-arrow has-tooltipl-multiline has-tooltip-text-right has-tooltip-left" data-tooltip={item.trend.verdict} style={{backgroundColor: "green", borderRadius: 80, height: 24, padding: 0, margin: 0, border: 0}}> */}
                    {item.trend ? <span className="icon has-tooltip-arrow has-tooltip-text-right has-tooltip-left" data-tooltip={item.trend.verdict}>
                      <i className={`mdi mdi-arrow-right-thick mdi-18px mdi-light ${rotation}`} style={{backgroundColor: trendColor, borderRadius: 18, height: 18, lineHeight: "18px", padding: 0, margin: 0, border: 0}}></i>
                    </span> : ''}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </Columns.Column>
    </Columns>
    : ''}

    {/* <Columns centered="true" style={{marginTop: 12}}>
      <Columns.Column>
        <table className={isActive && (apiData !== null) ? 'table is-hoverable' : 'table is-hoverable is-hidden'} style={{marginLeft: 'auto', marginRight: 'auto'}}>
          <thead>
            <tr>
              <th></th>
              <th>2 year dep.</th>
              <th>1 year dep.</th>
              <th>1 year forecast</th>
            </tr>
          </thead>
          <tbody>
            {apiData && apiData.depSummary && apiData.depSummary.map((item) => {
              return (
                <tr class="has-text-centered">
                  <td>{item.modelYear}</td>
                  <td>{item.dep2Year ? (item.dep2Year < 50 ? item.dep2Year + '%' : 'N/A') : 'N/A'}</td>
                  <td>{item.dep1Year ? (item.dep1Year < 50 ? item.dep1Year + '%' : 'N/A') : 'N/A'}</td>
                  <td className={item.forecast1Year && item.forecast1Year < 50 ? (item.forecast1Year >= 0 ? 'has-text-success' : 'has-text-danger') : ''}>{item.forecast1Year ? (item.forecast1Year < 50 ? item.forecast1Year + '%' : 'N/A') : 'N/A'}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </Columns.Column>
      <Columns.Column>
        <Notification style={{padding: "9px"}} className={isActive && (apiData !== null) ? 'is-size-7' : 'is-size-7 is-hidden'}>
          <p>The chart shows the depreciation curve for the model year selected, and a year before and after for comparison. Buying when the depreciation curve has flattened will lead to the lowest car ownership cost.</p>
          <p style={{marginTop: 8}}>Days on Market (DOMs) represented by a band shows the average number of days each listing stays open. The wider the band, the longer cars are taking to sell. DOM is often a leading indicator for upcoming price drops.</p>
          <p style={{marginTop: 8}} className="is-flex is-align-items-center"><img alt="Tip" src="/tip-58.png" style={{width: 32}} />Tip: Hovering over a specific year in the legend will hide other years. The bands around it show the Days on Market trend (wider means longer DOM).</p>
        </Notification>
      </Columns.Column>
    </Columns>
     */}
    {isActive && shouldRenderOnSaleListings ? (
      <>
      {/* <Heading size={5}>Best Deals <a onClick={onClickSeeMoreListings} style={{marginLeft: "20px", fontSize: "80%"}}>More »</a></Heading> */}
      <OnSaleListings id="best-deals" isActive={true} selectedCarId={selectedCarId} selectedSearchOptionsUrlSlug={selectedSearchOptionsUrlSlug} maxNumberToShow="6" pageType="best-deals-preview" onClickSeeMoreListings={onClickSeeMoreListings}/>
      {/* <Heading size={5}>Price Drops <a onClick={onClickSeeMoreListings} style={{marginLeft: "20px", fontSize: "80%"}}>More »</a></Heading> */}
      <p>&nbsp;</p>
      <OnSaleListings id="price-drops" isActive={true} selectedCarId={selectedCarId} selectedSearchOptionsUrlSlug={selectedSearchOptionsUrlSlug} maxNumberToShow="6" pageType="price-drops-preview" onClickSeeMoreListings={onClickSeeMoreListings} />
      </>
    ) : ''}
    </>
  )
}



// ==== Helper functions ====

function processData(apiData) {
  if (!(apiData.modelYears && apiData.modelYears.length > 0 && apiData.weeklyData && apiData.weeklyData.length > 0)) {
    return {}
  }

  let maxRangeBasedOnPrice = 40000
  let prices = []
  for (const [key, value] of Object.entries(apiData.weeklyData[0])) {
    if (key.includes('prices')) {
      prices.push(value)
    }
  }
  maxRangeBasedOnPrice = Math.max(...prices)
  
  let maxRangeBasedOnDoms = 60
  
  for (let d of apiData.weeklyData) {
    for (let t of apiData.modelYears) {
      if (d[`${t}-doms`] != null && d[`${t}-prices`] != null) {
        if (d[`${t}-doms`] == 0) {
          d[`${t}-doms-open`] = d[`${t}-prices`]
          d[`${t}-doms-close`] = d[`${t}-prices`]
        } else {
          d[`${t}-doms-open`] = d[`${t}-prices`] + (d[`${t}-doms`] / maxRangeBasedOnDoms * maxRangeBasedOnPrice / 14)
          d[`${t}-doms-close`] = d[`${t}-prices`] - (d[`${t}-doms`] / maxRangeBasedOnDoms * maxRangeBasedOnPrice / 14)
        }
      }
    }
  }
  
  for (let i = 0; i < 52; ++i) {
    for (let t of apiData.modelYears) {
      if (i+52 < apiData.weeklyData.length-1 && apiData.weeklyData[i][`${t}-prices`] && apiData.weeklyData[i][`${t}-prices`] != null && apiData.weeklyData[i][`${t}-prices`] != 0
          && apiData.weeklyData[i+52][`${t}-prices`] && apiData.weeklyData[i+52][`${t}-prices`] != null && apiData.weeklyData[i+52][`${t}-prices`] != 0) {
        apiData.weeklyData[i][`${t}-yoy-dep`] = roundTwoSigs(((apiData.weeklyData[i][`${t}-prices`] / apiData.weeklyData[i+52][`${t}-prices`]) - 1) * 100)
      }
    }
  }
  return apiData
}


export default DepCurveControl

