import React from 'react'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import './Chart.css';

class Chart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      divId: `${this.props.id}-chartdiv`
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  componentDidUpdate() {
    if (this.chart) {
      this.chart.dispose();
    }
    if (this.props.chartData === null) {
      return
    }

    let chart = am4core.create(this.state.divId, am4charts.XYChart);
    chart.data = this.props.chartData;
    
    
    chart.legend = new am4charts.Legend();
    chart.legend.fontSize = "12px";
    chart.legend.itemContainers.template.paddingTop = 0;
    chart.legend.itemContainers.template.paddingBottom = 0;
    
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.maxTooltipDistance = 0; 
    
    if (this.props.noDateAxis !== true) {
      let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.minGridDistance = 60;
      dateAxis.startLocation = 0.5;
      dateAxis.fontSize = "12px"

      chart.cursor.xAxis = dateAxis;
    }

    this.props.setupAxesAndSeries(chart)
    
    this.chart = chart;
  }

  render() {
    const isHidden = (this.props.isActive) ? "" : "is-hidden"
    const classes = `chartdiv ${isHidden}`
    return (
      <div id={this.state.divId} className={classes} style={{ width: "100%" }}></div>
    );
  }

}

export default Chart
