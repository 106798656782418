import React from "react";
import { useLocation } from "react-router-dom";
import { log } from "../utils.js"

export function useGoogleAnalytics(pageTitle= 'Car Scout') {
  let location = useLocation();
  React.useEffect(() => {
    recordGAPageview(pageTitle, location.pathname, window.location.href)
  }, [location])
}

export function recordGAPageview(pageTitle, pathname, href) {
  log('ga pageview', pageTitle, pathname, href)
  if (window && window.gtag) {
    window.gtag('event', 'page_view', {
      page_title: pageTitle,
      page_location: href,
      page_path: pathname
    })
  }
}

export function recordGACustom(eventName, eventData) {
  log('ga custom', {eventName}, {eventData})
  if (window && window.gtag) {
    window.gtag('event', eventName, eventData)
  }
}