import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import { Container, Navbar, Tag } from 'react-bulma-components';
import './MainNavbar.css';

export default function MainNavbar() {
  return (
    <>
    <Navbar>
      <Container>
        <Navbar.Brand>
          <Navbar.Item>
            <img className="logo-mark" src="/carscout-logo-black.svg" alt="Car Scout Logo" />
            <h1><span className="highlight">Car</span> Scout</h1>
          </Navbar.Item>
        </Navbar.Brand>
        <Navbar.Menu>
          <Navbar.Container>
            <Navbar.Item><NavLink to={`/trends`}>Explore Trends</NavLink></Navbar.Item>
            <Navbar.Item><NavLink to={`/explore-by-price`}>Explore by Price</NavLink></Navbar.Item>
            {/* &nbsp;<Tag color="danger" rounded={true}>New</Tag> */}            
            {/* <Navbar.Item><NavLink to={`/foryou`}>For You</NavLink></Navbar.Item> */}
            <Navbar.Item><NavLink to={`/trackedcars`}>Tracked Cars</NavLink></Navbar.Item>
            {/* <Navbar.Item><NavLink to={`/trackedcars`}>Tracked Cars</NavLink>&nbsp;<Tag color="danger" rounded={true}>New</Tag></Navbar.Item> */}
            <Navbar.Item><a href="https://forms.gle/S2tdZG1HE37XZNNt8" target="_blank" rel="noopener noreferrer">Give Feedback</a></Navbar.Item>
          </Navbar.Container>
        </Navbar.Menu>
      </Container>
    </Navbar>
    </>
  )
}