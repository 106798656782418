import React, { useState, useEffect, useCallback } from 'react'
import { fetcher } from './utils.js'
import MainNavbar from './MainNavbar.js';
import { NavLink, useLoaderData } from "react-router-dom";
import { Box, Card, Media, Columns, Content, Container } from 'react-bulma-components'
import { useGoogleAnalytics } from './hooks/useGoogleAnalytics.js'
import SnapPoll from './SnapPoll.js';
import NewCarFeedback from './NewCarFeedback.js';

export function MakeSelectorPage() {
  useGoogleAnalytics('Explore Trends')
  const [apiData, setApiData] = useState([])

  useEffect(() => {
    fetcher("/db/get-cars-list")
      .then((res) => res.json())
      .then((json) => {
        const makes = [...new Set(json.map(item => item.make))].sort((a, b) => a.localeCompare(b))
        setApiData(makes)
      })
  }, [])

  // Discussion poll
  const poll = {
    id: 'poll-discuss-forum',
    typeOfResponse: 'multi',
    question: "Poll: Would you find a discussion feature useful?",
    options: [
      { title: 'Yes, in general.', value: 'yes-general' },
      { title: 'Yes, discussion by model.', value: 'yes-by-model' },
      { title: 'Yes, discussion by make.', value: 'yes-by-make' },
      { title: 'Meh.', value: 'meh' },
      { title: 'No, not that useful.', value: 'no' },
    ]
  }

  // Next feature poll
  const nextPoll = {
    id: 'poll-next-feature-1',
    typeOfResponse: 'multi',
    question: "Poll: What kind of features would be more helpful?",
    options: [
      { title: 'Cross shopping and comparison tools', value: 'cross-shopping' },
      { title: 'Buying and cost of ownership tools', value: 'buying' },
      { title: 'More data and trend visualization', value: 'more-visualization' },
      { title: 'Notifications for tracked cars and price drops', value: 'notifications' },
    ]
  }

  return (
    <>
    <MainNavbar />
    <Container>
      {/* <SnapPoll id={poll.id} question={poll.question} typeOfResponse={poll.typeOfResponse} options={poll.options} useLocalStorage={true} /> */}
      <Columns multiline="true">
      {apiData.map((item) => {
        return (
          <Columns.Column key={item} desktop={{size: '2'}} tablet={{size: 'one-quarter'}}>
            <a href={'/trends/' + item}>
              <Card>
                <Card.Image size="1by1" src={'/car-logos/' + item.toLowerCase().replace(/ /g, '-') + '.svg'}/>
                <Card.Content paddingless="true" textAlign="center">
                  <Media>
                    <Media.Item>
                      <h2 size={2} className="mx-4 py-3" style={{borderTop: '1px solid #efefef'}}>{item}</h2>
                    </Media.Item>
                  </Media>
                  <Content />
                </Card.Content>
              </Card>
            </a>
          </Columns.Column>
          )
        })}
      </Columns>
      <NewCarFeedback />
    </Container>
    </>
  )
}

export async function ModelLoader({params}) {
  const res = await fetcher(`/db/get-cars-list`)
  const cars = await res.json()
  const models = cars.filter((x) => { return x.make === params.make }).sort((a, b) => a.model.localeCompare(b.model))
  return {models, make: params.make}
}

export function ModelSelectorPage() {
  const {models, make} = useLoaderData()
  useGoogleAnalytics(`Trends for ${make}`)

  return (
    <>
    <MainNavbar />
    <Container>
      <Columns multiline="true" display={models.length > 0 ? '' : 'hidden'}>
      {models.map((item) => {
        return (
          <Columns.Column key={item.id} desktop={{size: '3'}} tablet={{size: 'one-quarter'}}>
            <a href={'/trends/' + item.id + '/' + item.make + '/' + item.model}>
            {/* <NavLink to={'/trends/' + item.id + '/' + item.make + '/' + item.model}> */}
              <Card>
                {/* <Card.Image size="4by3" src={'/model-marks/' + item.id + '.jpg'} /> */}
                <Card.Image size="4by3" src={'/model-marks/car-' + item.id + '.jpeg'} />
                <Card.Content paddingless="true" textAlign="center">
                  <Media>
                    <Media.Item>
                      <h2 size={3} className="mx-4 py-3" style={{borderTop: '1px solid #efefef'}}>{item.model}</h2>
                    </Media.Item>
                  </Media>
                  <Content />
                </Card.Content>
              </Card>
            {/* </NavLink> */}
            </a>
          </Columns.Column>
          )
        })}
      </Columns>
      <Box className={models.length > 0 ? 'is-hidden' : ''}>
        <h2>No models found. <NavLink to={`/trends`}>Go back to the list of manufacturers.</NavLink></h2>
      </Box>
      <NewCarFeedback />
   </Container>
    </>   
  )
}