import './App.css';
import React from 'react';
import MainNavbar from './MainNavbar.js';
import { useGoogleAnalytics } from './hooks/useGoogleAnalytics.js'

const App = () => {
  useGoogleAnalytics()
  return (
    <div className="app">
      <MainNavbar />
    </div>
  )
}

export default App
