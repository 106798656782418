import React from 'react'
import { Form } from 'react-bulma-components';
const { Radio } = Form;

const RadioListControl = ({title, data, onChange, dataTitle, hideTitle, selectedValue}) => {
  const idTextPrefix = title.toLowerCase().replace(/ /g, '-')
  const name = `${idTextPrefix}-options`
  console.log({selectedValue})
  return (
    <>
    {data.length > 0 && hideTitle !== false ?
      <p className="menu-label">{title}</p>
      :
      <></>
    }
    <ul onChange={(e) => onChange(e.target.value, dataTitle)}>
      {data.map((item) => {
        const idText = `${idTextPrefix}-${item}`
        return (
          <>
          <li key={idText}>
            <Radio
              name={name}
              className={name}
              value={item.value || item}
              id={idText}
              checked={selectedValue === (item.value || item)}
            >{item.title || item}</Radio>
          </li>
          </>
        )
      })}
    </ul>
    </>
  )
 }

export default RadioListControl